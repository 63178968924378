//INFO: 논문 게시판 보기, 수정
<script>
import { getLabPaper } from '@/api/platform/community' // 논문 조회
import { updatePaperTable } from '@/api/admin/ntis/ntisUpdate' // 논문 조회

export default {
  name: 'adminBoardPaperUpdate',
  data() {
    const boardNo = Number(this.$route.params.id) // 파라미터 게시글 번호

    // 게시글 번호가 일치하는 지재권 데이터 화면에 넣어주기
    return {
      boardNo: boardNo,
      paperPageNo: 0,
      labNo: 0,
      readPaperData: {} // 선택된 논문 보기
    }
  },
  mounted() {
    this.paperPageNo = Number(this.$route.params.page) // 프로젝트 게시판 페이지 번호
    this.labNo = Number(this.$route.params.labNo) // 프로젝트 게시판 페이지 번호

    const loadPaperData = async () => {
      const data = await getLabPaper(this.boardNo)

      // FIXME: 화면불러오기 전에 alert가 뜨면 좋겠다
      if (data.code === 'DB_DATA_NOT_FOUND') {
        alert('data가 없습니다')
        this.$router.push({ path: '/admin/board/paper' })
      }
      // 성과 연도 날짜 설정
      if (data.paperPubYear) {
        data.paperPubYear = data.paperPubYear.substr(0, 4)
      }

      // 발행일 날짜 설정
      if (data.paperIssueDate) {
        data.paperIssueDate = data.paperIssueDate.substr(0, 10)
      }

      this.readPaperData = data
    }
    loadPaperData()
  },
  methods: {
    // ********** 목록 버튼 **********
    goPaperList() {
      this.$router.push({
        path: `/admin/board/paper/${this.labNo}/${this.paperPageNo}`
      })
    },
    // ********** 저장 버튼 **********
    savePaper() {
      // 논문 수정 함수 호출
      updatePaperTable(this.boardNo, this.readPaperData.paperIssueDate)
      alert('논문이 수정되었습니다.')
      this.goPaperList()
    }
  }
}
</script>

<template>
  <!-- 내용 입력 시작 -->
  <div class="adminTable">
    <div id="paperUpdateForm">
      <!-- 수정 불가 정보 -->
      <table>
        <colgroup>
          <col width="10%" />
          <col width="40%" />
          <col width="10%" />
          <col width="40%" />
        </colgroup>
        <tbody>
          <tr>
            <th>연구실 번호</th>
            <td><input type="text" name="labNo" v-model="readPaperData.labNo" placeholder="연구실 번호" disabled /></td>
            <td></td>
            <td></td>
          </tr>
          <br />
          <span class="info_text">NTIS 정보</span>
          <tr>
            <th>논문명</th>
            <td>
              <input type="text" name="paperResultTitle" v-model="readPaperData.paperResultTitle" placeholder="논문명" disabled size="70" />
            </td>
            <th>키워드(한글)</th>
            <td>
              <input type="text" name="paperKeyword" v-model="readPaperData.paperKeyword" placeholder="키워드(한글)" disabled size="70" />
            </td>
          </tr>
          <tr>
            <th>학술지명</th>
            <td>
              <input
                type="text"
                name="paperJournalName"
                v-model="readPaperData.paperJournalName"
                placeholder="학술지명"
                disabled
                size="70"
              />
            </td>
            <th>키워드(영어)</th>
            <td>
              <input
                type="text"
                name="paperJournalNameEn"
                v-model="readPaperData.paperJournalNameEn"
                placeholder="키워드(영어)"
                disabled
                size="70"
              />
            </td>
          </tr>
          <tr>
            <th>ISSN 번호</th>
            <td>
              <input
                type="text"
                name="paperIssnNumber"
                v-model="readPaperData.paperIssnNumber"
                placeholder="ISSN 번호"
                disabled
                size="70"
              />
            </td>
            <th>원문여부</th>
            <td>
              <input type="text" name="paperSourceFlag" v-model="readPaperData.paperSourceFlag" placeholder="원문여부" disabled size="70" />
            </td>
          </tr>
          <tr>
            <th>저자명</th>
            <td><input type="text" name="paperAuthor" v-model="readPaperData.paperAuthor" placeholder="저자명" disabled size="70" /></td>
            <th>국내외구분</th>
            <td>
              <input
                type="text"
                name="paperNationType"
                v-model="readPaperData.paperNationType"
                placeholder="국내외구분"
                disabled
                size="70"
              />
            </td>
          </tr>
          <tr>
            <th>과제고유번호</th>
            <td>
              <input
                type="text"
                name="paperProjectId"
                v-model="readPaperData.paperProjectID"
                placeholder="과제고유번호"
                disabled
                size="70"
              />
            </td>
            <th>학술지구분</th>
            <td>
              <input type="text" name="paperJctType" v-model="readPaperData.paperJCType" placeholder="학술지구분" disabled size="70" />
            </td>
          </tr>
          <tr>
            <th>SCI 구분</th>
            <td>
              <input type="text" name="paperSciType" v-model="readPaperData.paperSciType" placeholder="SCI 구분" disabled size="70" />
            </td>
            <th>성과연도</th>
            <td><input type="text" name="paperPubYear" v-model="readPaperData.paperPubYear" placeholder="성과연도" disabled /></td>
          </tr>
          <tr>
            <th>초록</th>
            <td>
              <textarea type="text" name="paperAbstract" v-model="readPaperData.paperAbstract" placeholder="초록" disabled></textarea>
            </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <br />

      <!-- 수정 가능 정보 -->
      <span class="info_text">추가입력정보</span>
      <table>
        <colgroup>
          <col width="10%" />
          <col width="50%" />
          <col width="10%" />
          <col width="50%" />
        </colgroup>
        <tbody>
          <tr>
            <th>발행일</th>
            <td><input type="date" name="paperIssueDate" v-model="readPaperData.paperIssueDate" placeholder="발행일" /></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <!-- 버튼 구역 -->
      <div class="btnArea">
        <button class="e-btn wf__btn btn-primary" @click="goPaperList()">목록</button>
        <button type="submit" id="savePaperBtn" class="e-btn wf__btn btn-success ml-2" @click="savePaper">저장</button>
      </div>
    </div>
  </div>
  <!-- 내용 입력 끝 -->
</template>

<style scoped>
.info_text {
  color: #2a7097;
  margin-left: 10px;
  font-size: x-large;
}
#paperUpdateForm textarea {
  width: 540px;
  height: 100px;
}
.btnArea {
  margin-top: 30px;
}
</style>
